/* eslint-disable camelcase */
import axios from 'axios';
import EnvManager from '../../config/envManager';

const backend = axios.create({
  baseURL: EnvManager.BACKEND_URL,
  withCredentials: true,
});

export const getApplications = async () => {
  try {
    const response = await backend.get('/applications/');
    return response?.data;
  } catch (error) {
    return null;
  }
};

export const updateApplication = async ({
  uuid,
  name,
  displayName,
  url,
  icon,
  defaultRole,
  roles,
  feature_toggles,
}) => {
  try {
    const response = await backend.put('/applications', {
      uuid,
      name,
      url,
      icon,
      display_name: displayName,
      default_role: defaultRole,
      allowed_roles: roles,
      allowed_feature_toggles: feature_toggles,
    });
    return response?.data;
  } catch (error) {}
};

export const postApplication = async ({
  name,
  displayName,
  url,
  icon,
  defaultRole,
  roles = [],
  feature_toggles = [],
}) => {
  try {
    const baseApplicationAttributes = {
      name,
      url,
      icon,
      display_name: displayName,
      default_role: defaultRole,
    };

    if (roles.length > 0) {
      baseApplicationAttributes['allowed_roles'] = roles;
    }

    if (feature_toggles.length > 0) {
      baseApplicationAttributes['allowed_feature_toggles'] = feature_toggles;
    }

    const response = await backend.post('/applications/', baseApplicationAttributes);
    return response?.data;
  } catch (error) {
    /* TODO: Manage auth errors properly (401, 403) */
  }
};

export const deleteApplication = async (applicationId) => {
  try {
    const response = await backend.delete(`/applications/${applicationId}`);
    return response?.data;
  } catch (error) {}
};
